

var editores = {
	init: function () {

	    if ($('.EditorFull').length > 0) {
	      
	        $('.EditorFull').tinymce({
	            plugins: [
                " link image lists  print preview hr anchor",
                " wordcount code fullscreen insertdatetime media ",
                "table contextmenu template paste pastepop"
	            ],
	            theme: "modern",
	            language: 'pt_PT',
	            toolbar1: "newdocument | undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | formatselect fontselect fontsizeselect",
	            toolbar2: "cut copy pastepop |  bullist numlist | outdent indent blockquote |  link unlink anchor image media code | insertdatetime preview ",
	            toolbar3: "table | hr removeformat | subscript superscript | print fullscreen |   template  ",
	            setup: function (ed) {
	                if ($('#' + ed.id+':disabled').length) {
	                    ed.settings.readonly = true;
	                }

	            }
	        });
	    }
		
	    if ($('.EditorSimples').length > 0) {
	        $('.EditorSimples').tinymce({
	            plugins: " preview  print contextmenu fullscreen template pastepop",
	            theme: "modern",
	            menubar: false,
	            language: 'pt_PT',
	            toolbar_items_size: 'small',
	            toolbar1: "newdocument undo redo | pastepop | bold italic underline strikethrough | formatselect  fontsizeselect | bullist | code",
	            setup: function (ed) {
	                if ($('#' + ed.id + ':disabled').length) {
	                    ed.settings.readonly = true;
	                }

	            }
	        });
	    }



	},
	encode: function () {

	    if (typeof(tinyMCE) !== "undefined") {
	        tinyMCE.triggerSave();
	    }

		$("input:not(.EditorFull,.EditorSimples), textarea:not(.EditorFull,.EditorSimples)").each(function () {
			$(this).val(aux.htmlEncode($(this).val()));
		})

	},
	decode: function () {

		$("input:not(.EditorFull,.EditorSimples), textarea:not(.EditorFull,.EditorSimples)").each(function () {
			$(this).val(aux.htmlDecode($(this).val()));
		})

	}
}



var tabela = {
	
	init: function (tabelaHolderObj, opcoesOverride) {
        var _this = this;

        debugger;

        var options = {
            pageSize: 10,
            apiUrl: '',
            apiData: {},
            callback: function () { },
            rowClicked: function (data) { }
        };

		options = $.extend(true, options, opcoesOverride);

        console.log("Opções da tabela, ", options)


        tabelaHolderObj.hide();

        var actions= null;

        if ($("#hf_podeeditar").val() == "true") {
            actions = {
                filter: false, //Toggle visibility
                columnPicker: false, //Toggle visibility
                custom: [
                    $('<span class="js-remove">Remover Registos Selecionados</span>;')
                ]
            };
        }


		tabelaHolderObj.WATable({
			assyncPaginacao: true,
			pageSize: options.pageSize,
			pageSizes: [],
			filter: true,

			hidePagerOnEmpty: true,
			preFill: true,

			url: options.apiUrl,
			urlData: options.apiData,

			types: {
				string: { placeHolder: "Pesquisar... " },
				number: { placeHolder: "><=" },
				bool: { tooltip: " " },
				date: { utc: true, format: 'yyyy-mm-dd', datePicker: true }
			},
			//  actions: { filter: false, columnPicker: false },
			tableCreated: function (data) {

				tabela.onCreate(tabelaHolderObj);

				if (options.callback) {
					options.callback(data);
                }
            },
            dataCallback: options.dataCallback,
            rowClicked: function (data) {

                if (options.rowClicked) {
                    options.rowClicked(data);
                }
            },
            actions: actions
        })


	},
	onCreate: function (tabelaHolderObj) {
		'use strict'

		var _this = this;

		/* -- Impede a seleção de texto -- */
		tabelaHolderObj.find(".watable  tbody").disableSelection();


		/* -- Esconde a paginação se não tiver páginas -- */
		if (tabelaHolderObj.find(".pagination li.disabled").length == $(".pagination li").length - 1) {
			$(".pagination").hide();
		}

		/* -- Esconde a paginação se não tiver páginas -- */
		tabelaHolderObj.find(".watable  tbody tr").each(function () {
			var id = $(this).find("td:nth(1)").text();
			if (id.trim().length)
				$(this).attr("id", "tr_" + id);
			else
				$(this).remove();

			$(this).find("td:nth(4)").addClass("ordem");
		})

		/* -- Adiciona classes com o index da coluna -- */
		tabelaHolderObj.find(".watable  th").each(function () {
			$(this).addClass("tbcol tbcol" + $(this).index());
		})
		tabelaHolderObj.find(".watable  td").each(function () {
			$(this).addClass("tbcol tbcol" + $(this).index());
		})

        tabelaHolderObj.fadeIn();

        tabelaHolderObj.find(".js-remove").click(function () {
            var checked = tabelaHolderObj.data("WATable").getData(true);

            var linhasSelecionadas = checked.rows;
            var selected = [];
                console.log(linhasSelecionadas)
            if (linhasSelecionadas.length) {

                for (var i = 0; i < linhasSelecionadas.length; i++) {
                    selected.push(linhasSelecionadas[i].id);

                    console.log(linhasSelecionadas[i].id)
                }
            }


            if (selected.length) {

                if (confirm("Pretende remover os artigos selecionados?")) {

                    $.ajax({
                        type: "GET",
                        data: { a: 'remove', id: JSON.stringify(selected).replace("[", "").replace("]", "").replace(",", "-"), idsite: idSiteGlobal },
                        url: '/api/artigos/setProp.ashx',
                        dataType: "html",
                        success: function (xml) {

                            console.log(xml)
                            tabelaHolderObj.data("WATable").update();

                        },
                        error: function (XMLHttpRequest, textStatus, errorThrown) {

                        }
                    })
                }


            }

        });
	}

}



var tabs = {
    init: function (obj, callbackMenu, callbackSubmenu) {

        $(obj).find(">li").click(function (e) {
            e.preventDefault();
            var _thisObj = $(this),
                index = _thisObj.index(),
                thisPainel = $("#editores .painelshow:nth(" + index + ")");

            if (!_thisObj.hasClass("active")) {

                /* -- Se tiver filhos -- */
                if (_thisObj.find("ul").length) {

                    $(obj).addClass("open");
                    /* -- Seleciona o primeiro -- */
                    _thisObj.find("li:first-child").addClass("active").siblings().removeClass("active");
                    thisPainel.find(".subpainelshow").first().show().addClass("open").siblings().hide().removeClass("open");
                } else {
                    $(obj).removeClass("open");
                }

                /* -- Activa este item -- */
                _thisObj.addClass("active").siblings().removeClass("active");

                /* -- Mostra o painel relacionado -- */
                thisPainel.siblings().addClass("animacao").removeClass("open");
                setTimeout(function () {
                    thisPainel.siblings().hide();
                    thisPainel.show().addClass("animacao");

                    setTimeout(function () {
                        thisPainel.addClass("open");
                    },10);
                }, 201);
                

                /* -- CallBack -- */
                if (callbackMenu) {
                    callbackMenu(_thisObj);
                }
            }
            
        })

        $(obj).find("ul li").click(function (e) {

            e.stopPropagation();
            var _thisObj = $(this),
                index = _thisObj.index(),
                indexPai = _thisObj.parent().parent().index(),
                thisPainel = $("#editores .painelshow:nth(" + indexPai + ")")

            if (!_thisObj.hasClass("active")) {

                /* -- Activa este item -- */
                _thisObj.addClass("active").siblings().removeClass("active");

                /* -- Mostra o painel relacionado -- */
                thisPainel.find(".subpainelshow").addClass("animacao").removeClass("open");
                setTimeout(function () {
                    thisPainel.find(".subpainelshow").hide().removeClass("animacao");
                    thisPainel.find(".subpainelshow").eq(index).show().addClass("animacao");

                    setTimeout(function () {
                        thisPainel.find(".subpainelshow").eq(index).addClass("open");
                    }, 10);
                }, 201);



                /* -- CallBack -- */
                if (callbackSubmenu) {
                    callbackSubmenu(_thisObj);
                }
            }
        });


        $(obj).find(">li").first().click();
    }

}


var maTimer = {
	tempo: 4,
	init: function (elem, id, callback) {
		_this = this;

		if (elem.hasClass("aeliminar")) {
			if (_this.tempo > 0) {
				elem.addClass("aeliminar").text("Cancelar." + _this.tempo + "s! ");
				setTimeout(function () {
					_this.tempo--;
					maTimer.init(elem, id, callback)
				}, 1000);
			} else {

				callback();
				_this.tempo = 4;
			}
		} else {
			elem.text("Eliminar");
		}
	}
}
