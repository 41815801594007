

function iniciaEmpresa() {
    $(window).resize(function () {
        if ($(window).width() < 1300) {
            $("#div_edicao").addClass("onecol");
        } else {
            $("#div_edicao").removeClass("onecol");
        }
    }).trigger('resize');

    $("#editores .divs:first-child").fadeIn();

    $("#tabs>.tab").click(function () {
        var self = this;
        $(self).parent().find(".tab").removeClass("active").addClass("inactive");
        $(self).removeClass("inactive").addClass("active");

        $("#editores .divs").fadeOut(200);
        setTimeout(function () { $(".show_" + $(self).attr("id")).fadeIn(); }, 200);

        if ($(self).hasClass("tab_localizacao") && mapaExiste == false)
            setTimeout(function () { loadMapa(zoom, marcadores); }, 300);

        if ($(self).hasClass("tab_multimedia") && uploadExiste == false)
            setTimeout(function () { loadUpload(); }, 300);
    })

    if (podeEditar) {
        $(".btn_gravar").live("click", function () {
            if (Page_ClientValidate("CreateUserWizard1")) {
                encodeFields()
                __doPostBack($(".btn_gravar_ghost").attr("name"), '');
            } else {
                erro("Preencha os campos obrigatórios");
            }
        })

        $(".btn_remover_contacto").live("click", function () {
            encodeFields()
            __doPostBack($(this).parent().find(".btn_remover_contacto_ghost").attr("name"), '');
        })

        $('.btn_add_contacto').live("click", function (e) {
            $('#modal-content-new').modal({
                appendTo: 'form', persist: true,
                onClose: function () {
                    closeModal();
                }
            }); return false;
        });

        $(".btn_adicionar_submit").live("click", function () {
            if (Page_ClientValidate("CreateUserWizard2")) {
                encodeFields();
                __doPostBack($(this).parent().find(".btn_adicionar_submit_ghost").attr("name"), '');
            } else { }
        })
    }
    
}

/* CLOSE MODAL NOVO CONTACTO - EMPRESA */
function closeModal() {
    $(".validation_holder").hide(); $(".error_popup").css("visibility", "hidden"); $.modal.close();
    $(".popup_input").val("");
    $(".popup_ddl").val("1");
}

function encodeFields() {
    $("input,textarea").each(function () {
        $(this).val(htmlEncode($(this).val().replace(/\n/gi,'')));
       // $(this).css("color", $(this).css("background-color"));
    })
}

function decodeFields() {
    $("input,textarea").each(function () {
        $(this).val(htmlDecode($(this).val()));
        //$(this).css("color", "#555");
    })
}

function encodeTinymceFields() {
    if( $("#resumo_pt").length>0)
        $("#resumo_pt").val(htmlEncode(tinyMCE.get('resumo_pt').getContent().replace(/\n/gi, '')));
    if( $("#texto_pt").length>0)
        $("#texto_pt").val(htmlEncode(tinyMCE.get('texto_pt').getContent().replace(/\n/gi, '')));
    if ($("#nome1_pt").length > 0)
        $("#nome1_pt").val(htmlEncode(tinyMCE.get('nome1_pt').getContent().replace(/\n/gi, '')));

    if ($("#resumo_pt1").length > 0)
        $("#resumo_pt1").val(htmlEncode(tinyMCE.get('resumo_pt1').getContent().replace(/\n/gi, '')));
    if ($("#texto_pt1").length > 0)
        $("#texto_pt1").val(htmlEncode(tinyMCE.get('texto_pt1').getContent().replace(/\n/gi, '')));
    if ($("#nome1_pt1").length > 0)
        $("#nome1_pt1").val(htmlEncode(tinyMCE.get('nome1_pt1').getContent().replace(/\n/gi, '')));
}





function inputLimiter(e, tipo) {
    var AllowableCharacters = '';
    if (tipo == 'letras') { AllowableCharacters = ' ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; }
    if (tipo == 'int') { AllowableCharacters = '1234567890'; }
    if (tipo == 'float') { AllowableCharacters = '1234567890.'; }
    if (tipo == 'moeda') { AllowableCharacters = '1234567890,'; }
    if (tipo == 'codpostal') { AllowableCharacters = '1234567890-'; }
    if (tipo == 'urlsafe') { AllowableCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-0123456789'; }

    var k = document.all ? parseInt(e.keyCode) : parseInt(e.which);
    if (k != 13 && k != 8 && k != 0) {
        if ((e.ctrlKey == false) && (e.altKey == false)) {
            return (AllowableCharacters.indexOf(String.fromCharCode(k)) != -1);
        } else {
            return true;
        }
    } else {
        return true;
    }
}